@import '../../../styles/app.scss';

.primaryButton {
  background: var(----button-bg, #3978f6);
  display: flex;
  padding: 7px 10.5px;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  margin-left: 6px;
  cursor: pointer;
}

.secondaryButton {
  background: var(----button-secondary-bg, #474d84);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--text-color);
  border: none;
  height: 35px;
  margin-left: 6px;
  padding: 7px 10.5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    background: var(----button-secondary-bg, #474d84);
    color: var(----text-color, rgba(255, 255, 255, 0.9));
  }
}

.activeButton {
  background: var(----button-secondary-bg, #474d84);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--text-color);
  border: none;
  height: 35px;
  width: 95px;
  margin-left: 6px;
  padding: 7px 10.5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    background: #72b23e;
    color: var(----text-color, rgba(255, 255, 255, 0.9));
  }
}

.divider {
  height: 1px;
  align-self: stretch;
  background: #787878;
  margin-bottom: 0px;
  margin-top: 0px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container > div {
  width: 100%;
}

.pieHeader {
  font-size: 20px;
  font-weight: 600;
  height: 24px;
}

.statsLabel {
  font-size: 16px;
  height: 22px;
}

.statsValue {
  font-size: 24px;
  font-weight: 700;
}

.navHeader {
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  text-transform: capitalize;
  cursor: pointer;
}

.navActive {
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 28px;
  text-transform: capitalize;
  cursor: pointer;
}

.modelHeader {
  overflow: hidden;
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: capitalize;
  margin-left: 6px;
}

.confidenceSettings {
  min-width: 250px;
}

.copyEmailCard {
  display: flex;
  padding: 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  right: 25px;
  bottom: 10%;
  border-radius: 5px;
  background: var(
    ----background,
    radial-gradient(
      212.51% 67.37% at 4.74% 0%,
      var(----background-start, #333867) 20%,
      var(----background-end, #17193b) 100%
    )
  );
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.5);
  width: 380px;
}

.copyEmailProgress {
  color: #030303;
  font-weight: 700;
}

.filtersContainer {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
}

.tabNav {
  margin: 0;
  display: flex;
  height: 30px;
}

.tabLink {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  margin-left: 0px;
}

.tabHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}

.emailBody {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.emailDetailsContainer {
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.emailDetailsCard {
  display: flex;
  width: 750px;
  max-height: 800px;
  max-width: 900px;
  padding: 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  right: 0px;
  background: var(
    ----background,
    radial-gradient(
      212.51% 67.37% at 4.74% 0%,
      var(----background-start, #333867) 20%,
      var(----background-end, #17193b) 100%
    )
  );
}

.emailDetailsHeader {
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: var(--Typography-Weight-bold, 700);
  line-height: 28px;
}

.llmHeader {
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--Typography-Weight-bold, 700);
  line-height: 28px; /* 175% */
}

.llmCard {
  display: flex;
  width: 422px;
  padding: 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  border-radius: 5px;
  background: var(
    ----background,
    radial-gradient(
      212.51% 67.37% at 4.74% 0%,
      var(----background-start, #333867) 20%,
      var(----background-end, #17193b) 100%
    )
  );
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.5);
}

.llmSummary {
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
}

.thresholdSettingsContainer {
  border-radius: 8px;
  color: white;
  width: 100%;
  margin: 20px auto;

  .thresholdGroup {
    display: flex;
    justify-content: space-between;

    .thresholdItem {
      display: flex;
      flex-direction: column;
      align-items: start;

      label {
        margin-bottom: 8px;
        font-weight: 400;
      }

      .rangeInput {
        display: flex;
        align-items: center;
        gap: 8px;

        input[type='number'] {
          background: none;
          border: none;
          margin-right: 3px;
          color: white;
          width: 50px;
          border-radius: 8px;
          &:focus {
            outline: none;
            border-color: #5e5eff;
          }
        }
      }
      .inputField {
        display: flex;
        align-items: center;
        width: 80px;
        padding: 6px 10px 6px 6px;
        background-color: #040620;
        color: white;
        border-radius: 4px;
        text-align: right;
        margin-bottom: 0px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.hiddenFileInput {
  display: none;
}

.uploadFile {
  cursor: pointer;
  border: 1px solid green($color: #000000);
  padding: 10px;
  height: 16px;
  margin-right: 10px;
}

.samplingExclusionsContainer {
  width: 85%;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  background: #040620;
  margin-left: 6px;
  margin-right: 12px;
}

.selectedFile {
  width: 70%;
  border-left: 1px solid #787878;
  margin-top: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.checkBoxIcon {
  border: 2px solid #787878 !important;
  background: none !important;
  width: 15px !important;
  height: 15px !important;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 10px;
}

input[type='checkbox'] {
  border: 2px solid #787878 !important;
  width: 15px;
  height: 15px;
  background: #010001 !important;
}

.navItem {
  background: rgba(0, 0, 0, 0.24);
  padding-right: 3px;
  color: $white !important;
  &:hover {
    color: $white !important;
  }
}

.navLink {
  background: transparent !important;
  color: $white !important;
  font-family: 'Poppins' !important;
  &:hover {
    color: $white !important;
  }
}

.linkText {
  color: $white !important;
  font-family: 'Poppins' !important;
}

.tabPane {
  background: rgba(0, 0, 0, 0.24);
}

.extensions {
  padding: 5px;
  margin-right: 3px;
  border-radius: var(--Radius-S, 4px);
  background: #666;
  width: auto;
  height: auto;
}

.emailModal {
  display: flex;
  width: 750px;
  height: 90vh;
  max-width: 900px;
  padding: 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 10px;
  position: absolute;
  right: 0px;
  background: var(
    ----background,
    radial-gradient(
      212.51% 67.37% at 4.74% 0%,
      var(----background-start, #333867) 20%,
      var(----background-end, #17193b) 100%
    )
  );
}

.emailModalHeader {
  color: var(----text-color, rgba(255, 255, 255, 0.9));
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: bold !important;
  line-height: 28px;
  margin-top: 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.extensionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100% !important;
  height: 80px;
  overflow-y: scroll;
  margin-bottom: 4px;
}

.searchInput {
  cursor: pointer;
  min-width: 240px;
  border-radius: 8px !important;
}

.errorMessage {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  color: #db2a34;
  font-size: 14px;
}

.tableContainer {
  min-height: 280px;
  max-height: 700px;
  width: 100% !important;
  overflow-y: scroll;
  overflow-x: auto;
}

.subjectFormatter {
  max-height: 80px;
  overflow-y: scroll;
}

.emailLabeledTable {
  width: 100%;
}
.emailLabeledTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: start;
}
.emailLabeledTable tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emailLabeledTable tr td:first-child {
  width: 2%;
}
.emailLabeledTable tr th:first-child {
  width: 2%;
}
.emailLabeledTable tr td:nth-child(2) {
  width: 18%;
}
.emailLabeledTable tr th:nth-child(2) {
  width: 18%;
}
.emailLabeledTable tr td:nth-child(3) {
  width: 15%;
}
.emailLabeledTable tr th:nth-child(3) {
  width: 15%;
}
.emailLabeledTable tr td:nth-child(4) {
  width: 12%;
}
.emailLabeledTable tr th:nth-child(4) {
  width: 12%;
}
.emailLabeledTable tr td:nth-child(5) {
  width: 18%;
}
.emailLabeledTable tr th:nth-child(5) {
  width: 18%;
}

.emailLabeledTable tr td:nth-child(6) {
  width: 12%;
}
.emailLabeledTable tr th:nth-child(6) {
  width: 12%;
}
.emailLabeledTable tr td:nth-child(7) {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailLabeledTable tr th:nth-child(7) {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailLabeledTable tr td:nth-child(8) {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailLabeledTable tr th:nth-child(8) {
  width: 15%;
}
.emailLabeledTable tr td:nth-child(9) {
  width: 8%;
}
.emailLabeledTable tr th:nth-child(9) {
  width: 8%;
}
.emailLabeledTable tr td:nth-child(10) {
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailLabeledTable tr th:nth-child(10) {
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}
